@import 'styles/import';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: vvw(48);
    height: vvw(48);

    img {
      width: 100%;
    }
  }

  .name {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    height: vvw(44);
    padding: vvw(12) vvw(18);

    font-weight: 500;
    font-size: vvw(20);
    white-space: nowrap;
    letter-spacing: -0.02em;

    color: $gray-50;
    text-align: center;
    border: vvw(1) solid $gray-50;

    &.active {
      color: $white;
      font-weight: 600;
    }
  }

  @media all and #{$horizontal} {
    gap: hvw(8);

    .name {
      height: hvw(44);
      padding: hvw(12) hvw(18);
      font-size: hvw(20);
      border: hvw(1) solid $main-black;
    }

    .icon {
      width: hvw(48);
      height: hvw(48);

      img {
        width: 100%;
      }
    }
  }
}
