@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexColumnCenter {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin slide($from, $to) {
  @keyframes slideUpAni {
    from {
      transform: translateY($from);
    }

    to {
      transform: translateY(0%);
    }
  }

  @keyframes slideDownAni {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY($to);
    }
  }
}

@mixin fadeOutAni() {
  @keyframes fadeOut {
    0% {
      opacity: 1;
      display: block;
      z-index: 100;
    }
    50% {
      opacity: 0.5;
      display: none;
      z-index: -1;
    }
    100% {
      opacity: 0;
      display: none;
      z-index: -1;
    }
  }
}
