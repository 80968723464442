@import 'styles/import';

.wrapper {
  @include flexCenter;

  gap: vvw(16);

  height: vvw(52);

  .input_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 vvw(20);

    width: vvw(696);
    height: vvw(52);

    .keypad_input {
      outline: none;
      border: none;

      width: 100%;

      pointer-events: none;

      text-align: left;
      font-family: Pretendard;
      font-size: vvw(20);
      font-style: normal;
      font-weight: 400;
      line-height: vvw(28); /* 140% */
      letter-spacing: -0.4px;
    }

    .hori_delete_one_btn {
      display: none;
    }

    .delete_all_btn {
      width: vvw(24);
      height: vvw(24);

      @include flexCenter;
    }
  }

  .delete_one_btn {
    width: vvw(52);
    height: vvw(52);

    @include flexCenter;
  }

  @media all and #{$horizontal} {
    width: hvw(760);
    height: hvw(52);

    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .input_wrapper {
      padding: 0 hvw(20);
      // width: hvw(760);
      // height: hvw(52);

      width: 100%;
      height: 100%;

      .keypad_input {
        font-size: hvw(20);
        line-height: hvw(28);
      }

      .delete_all_btn {
        width: hvw(24);
        height: hvw(24);
      }

      .hori_delete_one_btn {
        width: hvw(52);
        height: hvw(52);

        @include flexCenter;

        position: absolute;
        right: calc(hvw(-16) + hvw(-52));
      }
    }

    .delete_one_btn {
      display: none;
    }
  }
}
