@import 'styles/import';

.container {
  width: 100vw;
  // height: vvw(1331);

  display: flex;
  flex-direction: column;

  padding-left: vvw(110);

  position: relative;

  .swiper_wrapper {
    width: calc(vvw(860) + vvw(60));
  }

  // 가로형
  @media all and #{$horizontal} {
    display: flex;
    align-items: flex-start;

    height: hvw(868);

    position: absolute;
    top: 0;
    left: 0;

    padding: hvw(87) 0 hvw(41) hvw(476);

    .swiper_wrapper {
      width: calc(hvw(578) + hvw(390) + hvw(80));
    }
  }
}

.web_container {
  width: 100vw;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding-left: 110px;

  height: 868px;

  position: absolute;
  top: 0;
  left: 0;

  padding: 87px 0 41px 476px;

  .swiper_wrapper {
    width: calc(578px + 390px + 80px);
  }
}
