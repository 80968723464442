@import 'styles/import';

.loader_wrapper {
  width: 100%;
  height: vvw(96);

  @include flexColumnCenter;

  .loader {
    width: vvw(40);
    height: vvw(40);

    border: vvw(2) solid $gray-cc;
    border-bottom-color: transparent;
    border-radius: 100%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media all and #{$horizontal} {
  .loader_wrapper {
    height: hvw(96);

    .loader {
      width: hvw(40);
      height: hvw(40);
      border: hvw(2) solid $gray-cc;
      border-bottom-color: transparent;
      animation: rotation 1s linear infinite;
    }
  }
}
