@import 'styles/import';

.pagination_wrapper {
  @include flexCenter;

  width: vvw(860);
  height: vvw(157);
  padding-bottom: vvw(8);

  flex-shrink: 0;

  padding-bottom: vvw(8);

  @media all and #{$horizontal} {
    width: hvw(860);
    height: hvw(157);
    padding-bottom: hvw(8);

    padding-bottom: hvw(8);
  }
}
