@import 'styles/import';

.container {
  @include flexCenter;

  // width: calc(100% / 4);
  height: vvw(227);

  padding: vvw(43) vvw(38) vvw(44) vvw(38);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;

  position: absolute;
  right: 0;
  bottom: vvw(137);

  z-index: -1;

  background-color: $white;
  border: vvw(1) solid #444;

  animation: showQr 0.7s forwards;

  .qr {
    width: 100%;
    height: 100%;
  }

  @keyframes showQr {
    0% {
      bottom: 0;
    }

    100% {
      bottom: vvw(137);
    }
  }

  @media all and #{$horizontal} {
    width: 100%;
    // height: 240%;
    // padding: 14% 20%;

    right: 0;

    height: hvw(223);
    padding: hvw(41) hvw(59) hvw(42) hvw(59);

    @keyframes showQr {
      0% {
        bottom: 0;
      }

      100% {
        bottom: hvw(118);
      }
    }
  }

  // @media all and #{$square} {
  //   @keyframes showQr {
  //     0% {
  //       bottom: 0;
  //     }

  //     100% {
  //       bottom: vvw(100);
  //     }
  //   }
  // }
}
