@import 'styles/import';

.container {
  width: fit-content;
  display: flex;
  align-items: center;

  gap: vvw(20);

  .box {
    white-space: nowrap;
    min-width: fit-content;
    @include flexCenter;
    padding: vvw(6) vvw(10);

    font-size: vvw(16);
  }

  .hour {
    font-size: vvw(16);
    font-weight: 500;

    align-items: center;
  }

  .hours_desc {
    font-size: vvw(16);
    max-width: vvw(180);

    display: block;

    line-clamp: 1;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    align-items: center;
  }
}

@media all and #{$horizontal} {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    min-width: hvw(178.5);
    height: hvw(82);

    gap: hvw(8);
    padding: 0 hvw(20);
    flex: 1 0 0;

    .box {
      min-width: hvw(75);

      padding: hvw(6) hvw(10);
      font-size: hvw(16);
    }

    .hour {
      font-size: hvw(16);
      // line-height: 230%;

      height: hvw(42);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: hvw(2);
    }

    .hours_desc {
      font-size: hvw(16);

      max-width: hvw(160);
      height: hvw(42);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
