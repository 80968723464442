@import 'styles/import';

.swiper-pagination {
  width: 100%;
  height: vvw(52);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: vvw(20);

  position: relative;

  .page-wrapper {
    @include flexCenter;
    gap: vvw(8);

    height: vvw(52);
    padding: 0 vvw(20);

    // 현재 페이지
    .current {
      color: $main-black;
      font-family: 'Pretendard-Regular', 'Pretendard-Medium', 'Pretendard-Bold' !important;
      font-size: vvw(24);
      font-style: normal;
      font-weight: 700;
      line-height: vvw(30);
      letter-spacing: -0.48px;
    }

    // / 와 전체 페이지
    .slash,
    .total {
      color: $gray-cc;
      font-family: 'Pretendard-Regular', 'Pretendard-Medium', 'Pretendard-Bold' !important;
      font-size: vvw(24);
      font-style: normal;
      font-weight: 700;
      line-height: vvw(30);
      letter-spacing: -0.48px;
    }
  }
}

// 가로형
@media all and #{$horizontal} {
  .swiper-pagination {
    position: initial;

    height: hvw(52);

    display: flex;
    justify-content: center;
    align-items: center;

    gap: hvw(20);

    .page-wrapper {
      gap: hvw(8);
      height: hvw(52);
      padding: 0 hvw(20);

      .current,
      .slash,
      .total {
        font-size: hvw(24);
        line-height: hvw(30);
      }
    }
  }
}
