@import 'styles/import';

.wrapper {
  width: 100%;
  height: calc(100% - vvw(140));

  display: flex;
  flex-direction: column;

  border-left: 1px solid $gray-22;

  .arrow {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: vvw(150);

    img {
      width: vvw(26);
      height: 100%;
      object-fit: contain;
    }
  }
}

@media all and #{$horizontal} {
  .wrapper {
    height: calc(100% - hvw(140));

    display: flex;
    flex-direction: column;

    .arrow {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: hvw(150);

      img {
        width: hvw(26);
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
