@import 'styles/import';

.container {
  display: flex;
  align-items: center;
  padding: vvw(61) vvw(87);
  border-bottom: vvw(1) solid $main-black;
  gap: vvw(40);

  z-index: 100;

  height: vvw(212);

  .floor {
    font-size: vvw(96);
    font-style: normal;
    font-weight: 700;
    line-height: vvw(90); /* 93.75% */
    letter-spacing: -1.92px;
  }

  .desc {
    font-size: vvw(40);
    font-style: normal;
    font-weight: 600;
    line-height: vvw(50); /* 100% */
    letter-spacing: -0.8px;

    height: auto;
    max-height: vvw(100);

    overflow: hidden;
    word-break: break-all;

    text-align: left;
  }
}

@media all and #{$horizontal} {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    height: hvw(164);

    flex-shrink: 0;

    border-bottom: none;

    position: absolute;
    top: 0;
    left: 0;

    gap: hvw(40);

    padding: 0 hvw(60);

    .floor {
      @include flexCenter;

      height: hvw(164);

      color: #1e1e1e;

      text-align: center;
      font-family: Montserrat !important;
      font-size: hvw(96);
      font-style: normal;
      font-weight: 700;
      line-height: hvw(90); /* 93.75% */
      letter-spacing: -1.92px;

      padding: 0;
    }

    .desc {
      font-size: hvw(40);
      font-style: normal;
      font-weight: 600;
      line-height: hvw(40); /* 100% */
      letter-spacing: -0.8px;

      height: auto;
      max-height: hvw(80);
    }
  }
}
