@import 'styles/import';

.container {
  position: absolute;
  // top: vvw(730);
  top: 50%;
  transform: translateY(-50%);
  left: vvw(24);

  display: flex;
  flex-direction: column;

  border-radius: vvw(8);
  border: vvw(2) solid $gray-cc;
  background-color: $white;
  overflow: hidden;

  box-shadow: 0px vvw(3) vvw(15) 0px rgba(153, 153, 153, 0.4);

  .plus,
  .minus {
    width: vvw(64);
    height: vvw(72);

    @include flexColumnCenter;

    font-size: vvw(24);
  }

  .minus {
    .rectangle {
      width: vvw(20);
      height: vvw(2);
      background-color: $gray-44;
    }

    &.disabled {
      background-color: $gray-ea;

      .rectangle {
        background-color: $gray-cc;
      }
    }
  }

  .plus {
    border-bottom: vvw(2) solid $gray-cc;

    .icon {
      width: vvw(18);

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.disabled {
      background-color: $gray-ea;
    }
  }

  @media all and #{$horizontal} {
    position: absolute;
    // top: hvw(300);
    top: 50%;
    transform: translateY(-50%);
    left: hvw(24);

    display: flex;
    flex-direction: column;

    border-radius: hvw(8);
    border: hvw(2) solid $gray-cc;
    background-color: $white;

    box-shadow: 0px hvw(3) hvw(15) 0px rgba(153, 153, 153, 0.4);

    .plus,
    .minus {
      width: hvw(64);
      height: hvw(72);

      @include flexColumnCenter;

      font-size: hvw(24);
    }

    .minus {
      .rectangle {
        width: hvw(20);
        height: hvw(2);
        background-color: $gray-44;
      }

      &.disabled {
        background-color: $gray-ea;

        .rectangle {
          background-color: $gray-cc;
        }
      }
    }

    .plus {
      border-bottom: hvw(2) solid $gray-cc;

      .icon {
        width: hvw(18);
        height: 100%;

        img {
          height: 100%;
        }
      }
    }
  }
}
