@import 'styles/import';

.keypad_controller {
  @include flexCenter;
  align-items: flex-start;

  gap: vvw(16);
  padding-top: vvw(32);

  height: 100%;

  .verti_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: vvw(16);

    position: relative;
  }

  @media all and #{$horizontal} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: hvw(22);

    width: 100%;
    height: 100%;

    gap: hvw(16);

    .hori_wrapper {
      display: flex;
      gap: hvw(28);

      position: relative;
    }
  }
}
