@import 'styles/import';

.wrapper {
  margin-top: vvw(24);
  padding: 0px vvw(60);

  height: vvw(32);
  min-height: vvw(32);
  flex-shrink: 0;

  overflow: hidden;
  align-self: stretch;

  .keywords {
    display: flex;
    gap: vvw(8);

    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// 가로형
@media all and #{$horizontal} {
  .wrapper {
    margin-top: hvw(24);
    padding: 0px hvw(60);

    height: hvw(32);
    min-height: hvw(32);
    flex-shrink: 0;

    .keywords {
      display: flex;
      gap: hvw(8);
    }
  }
}
