@import 'styles/import';

.category_border {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: vvw(10);

  height: vvw(26);

  padding: 0 vvw(8);

  .category_name {
    text-align: center;
    font-size: vvw(14);

    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.36px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;

    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media all and #{$horizontal} {
    height: hvw(26);
    padding: 0 hvw(8);
    gap: hvw(10);

    .category_name {
      font-size: hvw(14);
    }
  }
}
