@import 'styles/import';

.container {
  height: 100%;
  position: relative;
  overflow: hidden;

  .map {
    position: absolute;
    width: calc(100% - 8.5rem);
    height: 100%;

    &.no_floor {
      width: 100vw;
    }

    .dabeeo {
      width: vvw(140);
      height: vvw(28);

      position: absolute;
      bottom: vvw(20);
      left: vvw(30);

      z-index: 90;

      img {
        height: 100%;
      }
    }
  }

  .qr_wrapper {
    @include flexColumnCenter;

    position: absolute;

    bottom: vvw(60);
    left: vvw(24);

    gap: vvw(23);

    background: $white;
    padding: vvw(36) vvw(44);
    border: vvw(2) solid $main-black;

    width: vvw(230);
    height: vvw(253);

    .text {
      color: $gray-44;
      text-align: center;
      font-size: vvw(20);
      font-style: normal;
      font-weight: 500;
      line-height: vvw(18);

      white-space: nowrap;
    }
  }

  @media all and #{$horizontal} {
    .map {
      .dabeeo {
        width: hvw(140);
        height: hvw(28);

        position: absolute;
        bottom: hvw(20);
        left: hvw(30);

        img {
          height: 100%;
        }
      }
    }

    .map {
      &.no_floor {
        width: 100vw;
      }
    }

    .qr_wrapper {
      bottom: calc(hvw(139) + hvw(60));

      bottom: hvw(60);
      left: hvw(24);

      gap: hvw(23);

      padding: hvw(36) hvw(44);
      border: hvw(2) solid $main-black;

      width: hvw(230);
      height: hvw(253);

      .text {
        font-size: hvw(20);
        line-height: hvw(18);
      }
    }
  }
}
