@import 'styles/import';

.container {
  width: vvw(580);
  background-color: #fff;
  height: vvw(490);

  @include flexColumnCenter;
  gap: vvw(40);

  .icon {
    display: flex;
    justify-content: center;
    width: vvw(210);

    img {
      height: 100%;
    }
  }

  .text {
    font-size: vvw(28);
    line-height: vvw(40);
  }

  @media all and #{$horizontal} {
    width: hvw(580);
    height: hvw(490);
    gap: hvw(40);
    .icon {
      width: hvw(210);

      img {
        height: 100%;
      }
    }

    .text {
      font-size: hvw(28);
      line-height: hvw(40);
    }
  }
}
