@import 'styles/import';

.container {
  position: relative;

  .badge {
    position: absolute;
    padding: vvw(12) vvw(20);
    top: 0;
    color: $white;
    @include flexCenter;
    font-size: vvw(18);

    &.display {
      background-color: #24d38a;
    }

    &.close {
      background-color: #999;
    }

    &.wait {
      background-color: $main-black;
    }
  }

  background-color: $white;
  width: vvw(860);

  display: flex;
  flex-direction: column;

  .header {
    @include flexColumnCenter;
    padding: vvw(60) vvw(60) vvw(60) vvw(40);
    border-bottom: 1px solid;
    gap: vvw(10);

    .title {
      font-size: vvw(28);
      text-align: center;
      font-weight: 700;
      line-height: vvw(38);

      word-break: break-all;
    }

    .date {
      @include flexCenter;
      gap: vvw(6);
      height: vvw(36);

      .calendar {
        width: vvw(24);
        height: vvw(24);

        img {
          height: 100%;
        }
      }
      .days {
        font-size: vvw(16);
        color: #777;
      }
    }
  }

  .content_image {
    flex: 1;
    height: vvw(620);
    max-height: vvw(1210);
    overflow-y: auto;

    &.loading {
      display: none;
    }

    &::-webkit-scrollbar {
      width: vvw(6);
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .top_button {
    z-index: 100;
    right: vvw(20);
    bottom: vvw(20);
    position: absolute;
    width: vvw(60);
    height: vvw(60);
    background-color: $main-black;

    @include flexCenter;

    .icon {
      width: vvw(28);
      height: vvw(28);

      img {
        height: 100%;
      }
    }
  }
}

@media all and #{$horizontal} {
  .container {
    width: hvw(860);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .badge {
      position: absolute;
      top: 0;
      padding: hvw(12) hvw(20);
      color: $white;
      @include flexCenter;
      font-size: hvw(18);

      &.display {
        background-color: #24d38a;
      }

      &.close {
        background-color: #999;
      }

      &.wait {
        background-color: $main-black;
      }
    }

    .header {
      @include flexColumnCenter;
      padding: hvw(60) hvw(60) hvw(30) hvw(40);
      border-bottom: 1px solid;
      gap: 0;

      .title {
        font-size: hvw(28);
        text-align: center;
        line-height: hvw(38);
      }

      .date {
        @include flexCenter;
        gap: hvw(6);
        height: hvw(36);

        .calendar {
          width: hvw(24);
          height: hvw(24);

          img {
            height: 100%;
          }
        }
        .days {
          font-size: hvw(16);
          color: #777;
        }
      }
    }

    .content_image {
      flex: 1;
      height: hvw(620);
      max-height: hvw(620);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: hvw(6);
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }

    .top_button {
      z-index: 100;
      right: hvw(20);
      bottom: hvw(20);
      position: absolute;
      width: hvw(60);
      height: hvw(60);
      background-color: $main-black;

      @include flexCenter;

      .icon {
        width: hvw(28);
        height: hvw(28);

        img {
          height: 100%;
        }
      }
    }
  }
}

.web_container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: $white;

  width: 860px;
  height: calc(100% - 150px);

  .badge {
    position: absolute;
    padding: 12px 20px;
    top: 0;
    color: $white;
    @include flexCenter;
    font-size: 18px;

    &.display {
      background-color: #24d38a;
    }

    &.close {
      background-color: #999;
    }

    &.wait {
      background-color: $main-black;
    }
  }

  .header {
    @include flexColumnCenter;
    padding: 60px 60px 60px 40px;
    border-bottom: 1px solid;
    gap: 0;

    .title {
      font-size: 28px;
      text-align: center;
      font-weight: 700;
      line-height: 38px;

      word-break: break-all;
    }

    .date {
      @include flexCenter;
      gap: 6px;
      height: 36px;

      .calendar {
        width: 24px;
        height: 24px;

        img {
          height: 100%;
        }
      }
      .days {
        font-size: 16px;
        color: #777;
      }
    }
  }

  .content_image {
    flex: 1;
    height: 620px;
    max-height: 620px;
    overflow-y: auto;

    &.loading {
      display: none;
    }

    &::-webkit-scrollbar {
      width: vvw(6);
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .top_button {
    z-index: 100;
    right: 20px;
    bottom: 20px;
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: $main-black;

    @include flexCenter;

    .icon {
      width: 28px;
      height: 28px;

      img {
        height: 100%;
      }
    }
  }
}
