@import 'styles/import';

.floor {
  height: 100%;
}

.tenant {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100vh;
  overflow: hidden;
}

.customBoard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100vh;
  overflow: hidden;
}

.resolution {
  @include flexCenter;

  position: absolute;
  top: 0;

  width: 100vw;
  height: 100vh;

  z-index: 300;

  background-color: $gray-f9;

  div {
    font-size: 0.6rem;
    letter-spacing: 0.0375rem;
    color: $main-black;
  }

  @media all and #{$horizontal} {
    div {
      font-size: 0.8rem;
    }
  }
}

.error {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: vvw(40);

    .title {
      font-size: vvw(24);
      color: $gray-99;
    }

    .go_to_main_btn {
      background-color: $white;
      width: vvw(320);
      padding: vvw(16);
      border-radius: vvw(4);
      border: vvw(1) solid $gray-22;
      font-size: vvw(24);
      color: $gray-99;
    }
  }

  @media all and #{$horizontal} {
    .container {
      gap: hvw(40);

      .title {
        font-size: hvw(24);
      }

      .go_to_main_btn {
        width: hvw(320);
        padding: hvw(16);
        border-radius: hvw(4);
        border: hvw(1) solid $gray-22;
        font-size: hvw(24);
      }
    }
  }
}

.login,
.not_found,
.error {
  width: 100%;
  height: 100vh;
  background-color: $gray-f9;
  position: absolute;
  top: 0;

  z-index: 300;
  @include flexColumnCenter;

  font-size: vvw(24);
  color: $gray-99;

  @media all and #{$horizontal} {
    font-size: hvw(24);
  }
}

.action_loading {
  z-index: 120;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.3);

  @include flexColumnCenter;

  color: $white;

  gap: vvw(47);

  .gif_wrapper {
    width: vvw(96);
    height: vvw(92);

    img {
      height: 100%;
    }
  }

  .loading_text {
    font-size: vvw(26);
    font-weight: 500;
  }

  @media all and #{$horizontal} {
    font-size: hvw(32);

    gap: hvw(47);

    .gif_wrapper {
      width: hvw(96);
      height: hvw(92);
    }

    .loading_text {
      font-size: hvw(24);
      font-weight: 400;
    }
  }
}
