@import 'styles/import';

.verti_header {
  z-index: 100;

  @include flexCenter;
  width: 100%;
  height: vvw(212);
  border-bottom: vvw(2) solid #222;

  font-size: vvw(56);
  font-weight: 600;

  line-height: vvw(60);
}

@media all and #{$horizontal} {
  .hori_header_list {
    z-index: 100;

    width: hvw(1002);
    height: hvw(184);

    font-size: hvw(60);
    font-weight: 600;

    display: flex;
    align-items: center;

    padding-left: hvw(100);
  }

  .hori_header_card {
    z-index: 100;

    width: fit-content;
    height: hvw(184);
    padding: 0 hvw(100);

    display: flex;
    align-items: center;
    flex-shrink: 0;

    > span {
      text-align: center;
      font-size: hvw(60);
      font-style: normal;
      font-weight: 600;
      line-height: hvw(70);
      letter-spacing: -1.2px;
    }
  }
}
