@import 'styles/import';

.container {
  @include flexColumnCenter;

  position: absolute;
  bottom: vvw(300);

  height: vvw(100);
  padding: 0 vvw(30);

  border-radius: vvw(10);

  background-color: rgba(0, 0, 0, 0.4);

  transform: translate(-50%, -50%);
  left: 50%;

  .text {
    color: $white;
    font-weight: 400;
    font-size: vvw(20);
  }

  @media screen and #{$horizontal} {
    bottom: hvw(150);
    height: hvw(100);
    border-radius: hvw(10);

    .text {
      font-size: hvw(20);
    }
  }
}
