@import 'styles/import';

.floor_and_point {
  display: flex;
  align-items: center;

  padding: vvw(4) 0;

  height: vvw(30);
}

.category_wrapper {
  display: flex;
  align-items: flex-end;
}

// 가로형
@media all and #{$horizontal} {
  .floor_and_point {
    padding: hvw(4) 0;

    height: hvw(26);
  }
}

.web_floor_and_point {
  display: flex;
  align-items: center;
  // padding: 4px 0;
  max-height: 26px;
}
