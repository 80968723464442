// 한국어, 영어용 폰트
@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Regular.subset.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Medium.subset.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-SemiBold.subset.woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Bold.subset.woff');
  font-weight: 700;
}

// 중국어용(간체, 번체) 폰트 - 적용하고 싶은 곳에 직접 명시
@font-face {
  font-family: 'NotoSansSC';
  src: url('../fonts/NotoSansSC-Regular.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansSC';
  src: url('../fonts/NotoSansSC-Bold.otf');
  font-weight: 700;
}
