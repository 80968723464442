@import 'styles/import';

.container {
  position: absolute;

  width: vvw(240);
  padding: vvw(40) 0;

  top: 50%;
  left: 50%;

  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  transform: translate(-50%, -50%);

  @include flexColumnCenter;
  gap: vvw(20);

  .icon {
    width: vvw(110);
    height: vvw(110);
    img {
      height: 100%;
    }
  }

  .content {
    font-size: vvw(20);
  }

  @media all and #{$horizontal} {
    width: hvw(240);
    padding: hvw(40) 0;
    gap: hvw(20);

    .icon {
      width: hvw(110);
      height: hvw(110);
    }

    .content {
      font-size: hvw(20);
    }

    @keyframes showQr {
      0% {
        bottom: 0;
      }

      100% {
        bottom: hvw(136);
      }
    }
  }
}
