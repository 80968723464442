@import 'styles/import';

.date {
  display: flex;
  align-items: center;
  gap: vvw(6);

  height: vvw(34);

  .icon {
    width: vvw(24);
    height: vvw(24);

    img {
      height: 100%;
    }
  }

  .days {
    font-size: vvw(16);
    color: #777;
  }
}

@media all and #{$horizontal} {
  .date {
    display: flex;
    align-items: center;
    gap: hvw(6);

    height: hvw(34);

    .icon {
      width: hvw(24);
      height: hvw(24);

      img {
        height: 100%;
      }
    }

    .days {
      font-size: hvw(16);
      color: #777;
    }
  }
}
