@import 'styles/import';

.container {
  @include flexColumnCenter;
  height: 100%;

  padding: vvw(20);

  .category_controller {
    @include flexCenter;

    width: 100%;

    padding: 0;
    row-gap: vvw(0);

    gap: vvw(16);
    row-gap: vvw(20);
    flex-wrap: wrap;

    > div {
      display: flex;
      align-items: center;
      text-align: center;

      flex-shrink: 0;

      height: vvw(44);
      padding: vvw(12) vvw(18);

      font-weight: 500;
      font-size: vvw(20);
      white-space: nowrap;
      letter-spacing: -0.02em;

      background-color: $white;
      color: $gray-50;
      text-align: center;
      border: vvw(1) solid $gray-50;

      &.active {
        color: $white;
        font-weight: 600;
      }
    }
  }
}

@media all and #{$horizontal} {
  .container {
    padding: 0 hvw(20);

    .category_controller {
      gap: hvw(12);
      row-gap: hvw(20);

      height: 70%;

      > div {
        height: hvw(52);
        padding: hvw(12) hvw(22);

        font-size: hvw(22);
        border: hvw(1) solid $gray-50;
      }
    }
  }
}
