@import 'styles/import';

.qr_container {
  width: vvw(96);
  height: vvw(96);

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: vvw(4);

  .text {
    font-size: vvw(14);
  }

  .qr {
    width: vvw(72);
    height: vvw(72);

    padding: vvw(7);
  }

  // 가로형
  @media all and #{$horizontal} {
    width: hvw(96);
    height: hvw(96);

    gap: hvw(4);

    .text {
      font-size: hvw(14);
    }

    .qr {
      width: hvw(72);
      height: hvw(72);

      padding: hvw(7);
    }
  }

  // 정방형
  // @media all and #{$square} {
  //   width: vvw(80);
  //   height: vvw(80);

  //   .text {
  //     font-size: vvw(10);
  //   }

  //   .qr {
  //     width: vvw(64);
  //     height: vvw(64);

  //     padding: vvw(7);
  //   }
  // }
}
