@import 'styles/import';

.container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: vvw(300);
  min-height: vvw(300);

  border-top: vvw(1) solid $main-black;

  .title_container {
    @include flexCenter;

    width: 100%;
    height: vvw(70);

    position: relative;

    &.border_bottom {
      border-bottom: vvw(1) solid $gray-cc;
    }

    .title {
      font-size: vvw(24);
      font-weight: 600;

      position: relative;

      .border_after {
        width: 100%;
        height: vvw(4);

        position: absolute;
        bottom: vvw(-15);
        left: 0;

        animation: path 1s forwards;

        @keyframes path {
          0% {
            width: 0;
          }

          100% {
            width: 100%;
          }
        }
      }
    }
  }

  .facilities {
    @include flexColumnCenter;

    height: calc(vvw(300) - vvw(72));
    padding: vvw(20);

    .facilities_wrapper {
      @include flexCenter;
      flex-wrap: wrap;

      width: 100%;

      gap: vvw(16);
      row-gap: vvw(20);

      padding: 0;
    }
  }

  @media all and #{$horizontal} {
    width: calc(100vw - hvw(136));
    height: hvw(140);
    min-height: hvw(140);

    justify-content: flex-end;
    border: none;
    border-top: hvw(1) solid $main-black;

    .facilities {
      height: hvw(140);

      .facilities_wrapper {
        padding: 0 hvw(200);

        gap: hvw(16);
        row-gap: hvw(16);
      }
    }

    .title_container {
      position: relative;
      height: auto;

      .title {
        display: none;
      }
    }
  }
}
