@import 'styles/import';

.container {
  display: flex;

  width: 100%;
  height: 100%;

  border-left: vvw(2) solid #222;

  .qr {
    @include flexColumnCenter;

    width: 100%;
    height: 100%;

    gap: vvw(6);

    .icon {
      width: vvw(40);
      height: vvw(40);

      > img {
        height: 100%;
        object-fit: contain;
      }
    }

    .text {
      white-space: nowrap;
      font-size: vvw(24);
      font-weight: 500;
    }
  }

  @media all and #{$horizontal} {
    position: relative;

    border-left: hvw(1) solid #222;

    height: hvw(120);

    .qr {
      @include flexColumnCenter;

      flex-direction: row;

      gap: hvw(8);

      .icon {
        width: hvw(40);
        height: hvw(40);
      }

      .text {
        font-size: hvw(30);
        padding-bottom: hvw(2);
      }
    }
  }
}

.web_container {
  display: flex;
  position: relative;
  width: 100%;
  height: 120px;

  border-left: 1px solid #222;

  .qr {
    @include flexColumnCenter;
    flex-direction: row;
    width: 100%;
    height: 100%;

    gap: 8px;
    .icon {
      width: 40px;
      height: 40px;

      > img {
        height: 100%;
        object-fit: contain;
      }
    }

    .text {
      white-space: nowrap;
      font-size: 30px;
      font-weight: 500;
      padding-bottom: 2px;
    }
  }
}
