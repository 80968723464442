@import 'styles/import';

.container {
  width: 100%;
  height: 100%;

  @media all and #{$horizontal} {
    display: flex;
    flex-direction: column;

    height: 100%;

    &.list_padding_bottom {
      padding-bottom: hvw(20);
    }
  }
}
