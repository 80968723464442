@import 'styles/import';

.web_container {
  cursor: pointer;

  display: flex;

  gap: 16px;

  width: 100%;
  height: 120px;

  .button_wrapper {
    @include flexColumnCenter;

    gap: 0;

    border-radius: 0;

    background-color: $gray-f9;
    width: 136px;
    height: 100%;
    z-index: 130;

    border: none;

    border-left: 1px solid $gray-22;
    border-right: 1px solid $gray-22;

    &.active {
      background-color: $gray-99;
    }

    .icon {
      width: 52px;
      height: 52px;

      position: relative;

      .arrow_hori {
        display: block;
        position: absolute;
        top: 0px;
        right: -18px;
        width: 18px;
        height: 18px;

        img {
          width: 100%;
        }
      }
    }

    .current_lang {
      font-size: 20px;
      line-height: 28px;
      color: $gray-44;
    }

    .arrow {
      display: none;
    }

    .arrow_hori {
      display: none;
    }
  }

  position: relative;

  .select_box {
    @include flexColumnCenter;

    position: absolute;

    left: -72px;
    bottom: 118px;

    width: 210px;

    z-index: -1;

    gap: 0;
    padding: 0;

    border-radius: 0;
    background-color: $gray-18;

    border-top: 1px solid $gray-77;
    border-left: 1px solid $gray-77;
    border-right: 1px solid $gray-77;
    border-bottom: none;

    animation: showSelectBoxAni 0.7s forwards;

    @keyframes showSelectBoxAni {
      0% {
        bottom: 0;
      }

      100% {
        bottom: 118px;
      }
    }

    .lang {
      @include flexCenter;
      flex: 1;
      min-height: 68px;
      font-size: 20px;
      line-height: 28px;
      background-color: $gray-18;
      color: $gray-77;

      padding: 0 20px;

      &.active {
        color: $white;
      }
    }
  }
}
