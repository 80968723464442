@import 'styles/import';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: vvw(140);
  max-height: vvw(182);

  background: #fff;

  flex-shrink: 0;

  .title {
    width: 100%;
    min-height: vvw(80);

    padding: vvw(32) vvw(60) vvw(6) vvw(60);

    color: #060a1b;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: break-all;

    text-align: center;
    font-size: vvw(30);
    font-style: normal;
    font-weight: 700;
    line-height: vvw(42); /* 140% */
    letter-spacing: -0.63px;
  }

  .info_group {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: vvw(6) vvw(60) vvw(24);
    height: vvw(60);

    width: 100%;
  }

  // 가로형
  @media all and #{$horizontal} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: hvw(592);
    min-height: auto;
    max-height: hvw(260);

    flex-shrink: 0;

    .title {
      min-height: auto;
      max-height: hvw(200);

      padding: hvw(32) hvw(60) hvw(6) hvw(60);

      text-align: left;

      font-size: hvw(30);
      line-height: hvw(42); /* 140% */

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .info_group {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      padding: hvw(6) hvw(60) hvw(24);
      height: hvw(60);
    }
  }
}
