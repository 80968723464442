@import 'styles/import';

.container {
  // position: absolute;
  padding: vvw(12) vvw(20);
  background-color: cornflowerblue;
  color: $white;

  font-size: vvw(18);
  white-space: nowrap;

  @include flexCenter;

  &.display {
    background-color: #24d38a;
  }

  &.close {
    background-color: #999;
  }

  &.wait {
    background-color: $main-black;
  }

  @media all and #{$horizontal} {
    padding: hvw(12) hvw(20);
    background-color: cornflowerblue;
    color: $white;

    font-size: hvw(18);
  }
}
