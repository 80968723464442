@import 'styles/import';

.word_box {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: vvw(2);
  background: $state-blue-bg;

  .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;

    white-space: nowrap;

    overflow: hidden;
    color: $state-blue;
    text-overflow: ellipsis;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 500;

    word-break: keep-all;
  }

  &.item {
    height: vvw(32);
    padding: 0 vvw(10);

    .text {
      font-size: vvw(16);
      line-height: vvw(24); /* 150% */
      letter-spacing: vvw(-0.32);
    }
  }

  &.list {
    height: vvw(36);
    padding: 0 vvw(16);

    .text {
      font-size: vvw(18);
      line-height: vvw(24); /* 133.333% */
      letter-spacing: vvw(-0.36);
    }
  }
}

@media all and #{$horizontal} {
  .word_box {
    border-radius: hvw(2);

    &.item {
      height: hvw(32);
      padding: 0 hvw(10);

      .text {
        font-size: hvw(16);
        line-height: hvw(24); /* 150% */
        letter-spacing: hvw(-0.32);
      }
    }

    &.list {
      height: hvw(36);
      padding: 0 hvw(16);

      .text {
        font-size: hvw(18);
        line-height: hvw(24); /* 133.333% */
        letter-spacing: hvw(-0.36);
      }
    }
  }
}
