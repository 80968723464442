@import 'styles/import';

.container {
  @include flexCenter;

  height: vvw(80);
  padding: 0 vvw(50);
  justify-content: space-between;
  font-size: vvw(18);

  .mall_info {
    display: flex;
    gap: vvw(40);
  }

  .hours_desc {
    @include flexCenter;

    white-space: nowrap;
    font-size: vvw(18);

    color: $gray-cc;
  }

  .lang {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  // @media all and #{$square} {
  //   height: vvw(60);
  //   padding: vvw(15) vvw(25);
  //   font-size: vvw(13);
  // }
}
