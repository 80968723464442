@import 'styles/import';

.loading {
  width: 100vw;
  height: 100vh;

  @include flexColumnCenter;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 300;

  gap: hvw(40);

  .loading_image {
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }

    .loading_text {
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translate(-50%, 50%);

      color: $white;
      opacity: 0.7;
      font-size: vvw(18);
    }

    .dots_loading {
      position: absolute;
      bottom: 18%;
      left: 50%;
      transform: translate(-50%, 50%);

      .dots {
        position: relative;

        width: vvw(20);
        height: vvw(20);
        border-radius: 50%;
        background-color: $white;
        opacity: 0.8;

        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;

        &::before,
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
        }

        &::before {
          left: vvw(-30);
          width: vvw(20);
          height: vvw(20);
          border-radius: 50%;
          background-color: $white;
          opacity: 0.8;

          animation: dot-flashing 1s infinite alternate;
          animation-delay: 0s;
        }

        &::after {
          left: vvw(30);
          width: vvw(20);
          height: vvw(20);
          border-radius: 50%;
          background-color: $white;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot-flashing {
          0% {
            background-color: $white;
            opacity: 0.8;
          }
          50%,
          100% {
            background-color: $white;
            opacity: 0.2;
          }
        }
      }
    }
  }
}

@media all and #{$horizontal} {
  .loading {
    gap: hvw(40);

    width: 100vw;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    .loading_image {
      // position: absolute;
      // top: 0;
      // left: 50%;

      // transform: translate(-50%, 0);

      img {
        width: 100vw;
        object-fit: cover;
      }

      .loading_text {
        bottom: 22%;
        font-size: hvw(18);
      }

      .dots_loading {
        bottom: 26%;

        .dots {
          margin-bottom: hvw(5);

          width: hvw(20);
          height: hvw(20);

          &::before {
            left: hvw(-30);
            width: hvw(20);
            height: hvw(20);
          }

          &::after {
            left: hvw(30);
            width: hvw(20);
            height: hvw(20);
          }
        }
      }
    }
  }
}
