@import 'styles/import';

.tabs_wrapper {
  position: relative;

  .point_select {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: vvw(72);

  gap: vvw(48);

  .loading {
    height: 100%;
  }

  .tab {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 100%;

    font-weight: 600;
    font-size: vvw(24);
    line-height: vvw(28);

    letter-spacing: -0.02em;

    .border_after {
      width: 100%;
      height: vvw(4);

      position: absolute;
      bottom: 0;
      left: 0;

      animation: path 1s forwards;

      @keyframes path {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }
  }

  @media all and #{$horizontal} {
    gap: hvw(48);

    min-height: hvw(68);

    .tab {
      font-size: hvw(24);
      line-height: hvw(28);

      .border_after {
        height: hvw(4);
      }
    }
  }
}
