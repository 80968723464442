@import 'styles/import';

.detail_grid {
  .detail {
    display: flex;

    position: relative;

    gap: vvw(20);

    .field_title {
      width: vvw(140);
      // height: vvw(48);

      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-size: vvw(18);
      font-weight: 600;
      line-height: vvw(24);
      letter-spacing: -0.108px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .field_content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      width: vvw(380);
      min-height: vvw(48);

      border-bottom: vvw(1) solid $gray-cc;

      .content {
        width: 100%;
        height: 100%;

        padding: vvw(13) vvw(8);

        font-size: vvw(18);
        font-style: normal;
        font-weight: 400;
        line-height: vvw(24);
        letter-spacing: -0.108px;

        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // word-break: break-all;

        white-space: pre-wrap; /* \n을 개행으로 처리 */
        word-wrap: break-word; /* 긴 단어가 있을 경우 자동으로 줄바꿈 */
        word-break: break-all; /* 긴 단어를 강제로 줄바꿈 */
        overflow-wrap: break-word; /* 현대 브라우저에서도 줄바꿈 지원 */
      }

      .guide {
        color: $gray-99;
        font-size: vvw(16);

        padding: vvw(4) vvw(8);
      }
    }
  }

  // 정방형
  // @media all and #{$square} {
  //   .detail {
  //     gap: vvw(20);

  //     .field_title {
  //       font-size: vvw(16);
  //       line-height: vvw(20);
  //     }

  //     .field_content {
  //       width: vvw(280);
  //       height: vvw(48);

  //       font-size: vvw(18);
  //       line-height: vvw(24);

  //       padding: 0 vvw(8);

  //       .guide {
  //         font-size: vvw(16);
  //       }
  //     }
  //   }
  // }

  // 가로형
  @media all and #{$horizontal} {
    .detail {
      gap: hvw(20);

      .field_title {
        width: hvw(120);
        // height: hvw(52);

        font-size: hvw(18);
        line-height: hvw(24);
      }

      .field_content {
        width: hvw(380);
        min-height: hvw(48);

        border-bottom: hvw(1) solid $gray-cc;

        .content {
          padding: hvw(13) hvw(8);

          font-size: hvw(18);
          line-height: hvw(24);

          white-space: pre-wrap; /* \n을 개행으로 처리 */
          word-wrap: break-word; /* 긴 단어가 있을 경우 자동으로 줄바꿈 */
          word-break: break-all; /* 긴 단어를 강제로 줄바꿈 */
          overflow-wrap: break-word; /* 현대 브라우저에서도 줄바꿈 지원 */
        }

        .guide {
          font-size: hvw(16);

          padding: hvw(4) hvw(8);
        }
      }
    }
  }
}
