@import 'styles/import';

.container {
  width: fit-content;
  display: flex;
  align-items: center;

  font-size: vvw(16);
  gap: vvw(20);

  .box {
    white-space: nowrap;
    min-width: fit-content;
    @include flexCenter;
    padding: vvw(6) vvw(10);

    font-size: vvw(16);
  }

  .desc {
    display: grid;
    grid-template-columns: auto 2fr;

    column-gap: vvw(4);
    line-height: vvw(20);

    white-space: nowrap;
    font-weight: 500;

    .closed_day {
      font-size: vvw(16);
    }
  }

  .noHoliday {
    font-size: vvw(16);
  }
}

@media all and #{$horizontal} {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    min-width: hvw(178.5);
    height: hvw(82);

    gap: hvw(6);

    padding: 0 hvw(20);

    flex: 1 0 0;

    .box {
      width: hvw(75);

      padding: hvw(6) hvw(10);
      font-size: hvw(16);
    }

    .desc {
      grid-template-columns: auto 2fr;

      column-gap: hvw(4);
      white-space: nowrap;

      height: 100%;

      align-items: center;

      .noHoliday {
        font-size: hvw(16);
        height: hvw(42);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .closed_day {
        font-size: hvw(16);
        line-height: 130%;
      }
    }
  }
}
