@import 'styles/import';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  position: relative;
}
