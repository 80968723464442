@import 'styles/import';

.container {
  @include flexColumnCenter;
  justify-content: flex-start;

  width: 100%;
  min-height: vvw(299);
  height: vvw(299);

  border-top: vvw(1) solid $main-black;

  @media all and #{$horizontal} {
    height: hvw(240);
    min-height: hvw(240);
  }
}

.web_container {
  @include flexColumnCenter;
  justify-content: flex-start;

  width: 100%;
  height: 240px;
  min-height: 240px;

  border-top: 1px solid $main-black;
}
