@import 'styles/import';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: vvw(20);

  width: 100%;

  padding: vvw(20);

  .buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    gap: vvw(4);
  }
}

@media all and #{$horizontal} {
  .container {
    display: flex;
    width: 100%;
    height: hvw(130);

    gap: hvw(20);

    padding: hvw(20);

    .buttons_wrapper {
      gap: hvw(10);
    }
  }
}
