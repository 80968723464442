@import 'styles/import';

.container {
  width: vvw(380);
  border: vvw(1) solid #eaeaea;

  .thumbnail {
    width: 100%;
    height: vvw(382);
    position: relative;

    &.loading {
      display: none;
    }

    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
      height: 100%;
    }

    .badge {
      position: absolute;
    }
  }

  .desc {
    height: vvw(260);
    padding: vvw(24);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    .title {
      width: vvw(332);

      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      font-weight: 700;
      font-size: vvw(28);
      color: #3b3b3b;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: vvw(20);

    .btn {
      @include flexCenter;
      width: 100%;
      padding: vvw(10) vvw(40);
      color: $white;
      font-size: vvw(20);
      line-height: vvw(34);
    }
  }

  @media all and #{$horizontal} {
    border: hvw(1) solid #eaeaea;
    min-width: hvw(382);
    width: hvw(382);
    height: hvw(640);

    &.isLast {
      margin-right: hvw(50);
    }

    .thumbnail {
      width: 100%;
      height: hvw(382);
      position: relative;

      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .desc {
      height: hvw(260);
      padding: hvw(24);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $white;
      .title {
        width: hvw(332);
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        font-weight: 700;
        font-size: hvw(28);
        color: #3b3b3b;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: hvw(20);

      .btn {
        @include flexCenter;
        width: 100%;
        background-color: $main-black;
        line-height: hvw(34);
        padding: hvw(10) hvw(40);
        color: $white;
        font-size: hvw(20);

        &:hover {
          background-color: #3b3b3b;
        }
      }
    }
  }
}

.webContainer {
  .badge {
    position: absolute;
  }

  border: vvw(1) solid #eaeaea;
  min-width: 382px;
  max-width: 19%;
  height: calc(100% - 30px);
  max-height: 640px;

  &.isLast {
    margin-right: hvw(50);
  }

  .thumbnail {
    width: 100%;
    height: 60%;

    position: relative;

    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .desc {
    height: 40%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    .title {
      width: 332px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      font-weight: 700;
      font-size: 28px;
      color: #3b3b3b;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .btn {
      @include flexCenter;
      width: 100%;
      background-color: $main-black;
      line-height: hvw(34);
      padding: hvw(10) hvw(40);
      color: $white;
      font-size: 20px;

      &:hover {
        background-color: #3b3b3b;
      }
    }
  }
}
