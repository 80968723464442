@import 'styles/import';

.container {
  position: absolute;
  left: calc(50%);
  bottom: 50%;
  transform: translate(-50%, 50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // width: vvw(240);
  // height: vvw(235);

  max-width: vvw(600);
  height: vvw(120);

  padding: 0 vvw(80);

  background-color: rgba(0, 0, 0, 0.4);

  border-radius: vvw(10);

  z-index: 300;

  gap: vvw(20);

  .icon {
    width: vvw(80);
    height: vvw(80);

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > span {
    font-weight: 500;
    font-size: vvw(20);
    line-height: vvw(24);
    display: flex;
    align-items: center;
    color: #ffffff;

    word-break: break-all;
    text-align: center;
  }

  @media all and #{$horizontal} {
    // width: hvw(240);
    // height: hvw(235);

    max-width: hvw(600);
    height: hvw(120);

    padding: 0 hvw(80);

    border-radius: hvw(10);

    gap: hvw(20);

    .icon {
      width: hvw(80);
      height: hvw(80);
    }

    > span {
      font-size: hvw(20);
      line-height: hvw(24);
    }
  }
}
