@import 'styles/import';

.keypad_tabs {
  @include flexColumnCenter;

  gap: vvw(8);

  div {
    @include flexCenter;

    width: vvw(80);
    height: vvw(52);

    border: $border-black;
    color: $main-black;

    font-weight: 500;

    white-space: nowrap;

    font-weight: 500;
    font-size: vvw(22);
    line-height: vvw(28);

    &.active {
      color: $white;
    }
  }

  @media all and #{$horizontal} {
    flex-direction: row;

    gap: hvw(8);

    position: absolute;
    left: calc(hvw(-88) * 3 + hvw(-16) + hvw(-28));
    bottom: 0;

    div {
      width: hvw(80);
      height: hvw(52);

      font-size: hvw(22);
      line-height: hvw(28);
    }
  }
}
