@import 'styles/import';

.container {
  .select_box_wrapper {
    height: 100%;
    position: relative;

    .options {
      @include flexColumnCenter;

      position: absolute;
      bottom: vvw(71);
      left: 0;

      z-index: 250;
      background-color: #000;

      width: vvw(263);

      .option {
        display: flex;
        align-items: center;
        gap: vvw(8);

        width: 100%;
        height: vvw(68);

        color: $white;

        font-size: vvw(20);

        padding: 0 vvw(20);

        .point_icon {
          width: vvw(24);
          height: vvw(24);

          img {
            height: 100%;
          }
        }

        .point_name {
          color: $gray-77;

          &:active {
            color: $white;
          }
        }
      }
    }

    .select_box {
      width: vvw(263.5);
      height: vvw(70);

      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: vvw(4);

      padding: 0 vvw(20);

      .all {
        display: flex;
        align-items: center;
        width: 100%;
        color: $white;
        font-size: vvw(20);
      }

      .arrow {
        width: vvw(18);
        height: vvw(18);

        img {
          height: 100%;
        }
      }

      .current_select {
        display: flex;
        align-items: center;
        gap: vvw(8);
        width: 100%;

        .current_select_icon {
          width: vvw(24);
          height: vvw(24);

          img {
            height: 100%;
          }
        }

        .current_select_name {
          color: $white;
          font-size: vvw(20);
          line-clamp: 1;
          -webkit-box-orient: vertical;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }

      > div {
        width: vvw(18);
        height: vvw(18);
      }
    }
  }

  @media all and #{$horizontal} {
    .select_box_wrapper {
      .options {
        bottom: hvw(68);
        left: 0;

        width: hvw(263);

        .option {
          gap: hvw(8);
          height: hvw(68);

          font-size: hvw(20);

          padding: 0 hvw(20);

          .point_icon {
            width: hvw(24);
            height: hvw(24);
          }
        }
      }

      .select_box {
        width: hvw(263.5);
        height: hvw(68);

        gap: hvw(4);

        padding: 0 hvw(20);

        .all {
          font-size: hvw(20);
        }

        .arrow {
          width: hvw(18);
          height: hvw(18);
        }

        .current_select {
          gap: hvw(8);

          .current_select_icon {
            width: hvw(24);
            height: hvw(24);
          }

          .current_select_name {
            font-size: hvw(20);
          }
        }

        > div {
          width: hvw(18);
          height: hvw(18);
        }
      }
    }
  }
}
