@import 'styles/import';

.main_menu {
  @include flexColumnCenter;

  height: 100%;
  gap: vvw(6);

  .home_icon {
    width: vvw(40);
    height: vvw(40);

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .home_text {
    font-size: vvw(24);
    font-weight: 500;
    line-height: vvw(32);
    letter-spacing: -0.24px;
  }

  @media all and #{$horizontal} {
    @include flexCenter;
    flex-direction: row;

    gap: hvw(8);

    .home_icon {
      width: hvw(40);
      height: hvw(40);

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .home_text {
      font-size: hvw(30);
      padding-bottom: hvw(2);
    }
  }
}
