$white: #ffffff;
$gray-f9: #f9f9f9;
$gray-eb: #ebebeb;
$gray-ea: #eaeaea;
$gray-d9: #d9d9d9;
$gray-cc: #cccccc;
$gray-12: #121212;
$gray-18: #181818;
$gray-22: #222222;
$gray-44: #444444;
$gray-50: #505050;
$gray-77: #777777;
$gray-99: #999999;
$gray-dark: #060a1b;

$main-black: #272a36;

// point
$point-blue: #4869df;
$point-green: #76bba1;

// state
$state-blue: #7b8cc8;
$state-blue-bg: #f2f5ff;

$border-black: 1px solid $main-black;
$border-gray-22: 1px solid $gray-22;
