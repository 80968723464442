@import 'styles/import';

.tenant {
  @include flexCenter;
  justify-content: flex-start;

  background-color: $white;
  border: 1px solid #eaeaea;

  width: vvw(502);
  height: 100%;

  padding: vvw(28);
  padding-right: vvw(24);

  gap: vvw(24);

  .thumbnail {
    width: vvw(180);
    height: vvw(180);

    flex-shrink: 0;

    &.loading {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    gap: vvw(12);

    .title_wrapper {
      height: vvw(108);

      padding-top: vvw(6);

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      gap: vvw(12);

      .title {
        width: 100%;
        height: vvw(54);
        vertical-align: text-top;

        font-size: vvw(24);
        font-style: normal;
        font-weight: 600;
        line-height: vvw(27); /* 112.5% */
        letter-spacing: -0.48px;

        color: $gray-dark;

        word-break: break-word;

        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .category_wrapper {
      display: flex;
      height: vvw(60);
      align-items: flex-end;
      align-content: flex-end;

      gap: vvw(8);
      flex-shrink: 0;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .detail_wrapper {
      display: flex;
      align-items: center;

      gap: vvw(10);

      .category {
        @include flexCenter;

        gap: vvw(4);

        color: $gray-77;
        font-weight: 500;

        font-size: vvw(14);
        line-height: vvw(18);
      }
    }
  }

  // 가로형
  @media all and #{$horizontal} {
    max-width: hvw(448);
    width: 100%;
    height: hvw(184);

    gap: hvw(16);

    padding: 0 hvw(16);

    .thumbnail {
      width: hvw(148);
      height: hvw(148);

      > img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }

    .content {
      height: hvw(184);

      padding: hvw(16) 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: hvw(6);

      .title_wrapper {
        height: hvw(86);

        padding-top: 0;

        gap: hvw(4);

        .title {
          width: 100%;
          height: hvw(56);

          font-size: hvw(24);
          line-height: hvw(28); /* 116.667% */
        }
      }

      .category_wrapper {
        height: hvw(60);

        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: hvw(8);

        flex-shrink: 0;
        align-self: stretch;
        flex-wrap: wrap;
      }

      .detail_wrapper {
        gap: hvw(10);

        .category {
          @include flexCenter;

          gap: hvw(4);

          font-size: hvw(14);
          line-height: hvw(18);
        }
      }
    }
  }

  // 정방형
  // @media all and #{$square} {
  //   .thumbnail {
  //     width: vvw(120);
  //     height: vvw(120);
  //   }

  //   .content {
  //     gap: vvw(0);
  //     .title {
  //       font-size: vvw(20);
  //     }
  //   }
  // }
}

.web_tenant {
  @include flexCenter;
  // justify-content: flex-start;

  background-color: $white;
  border: 1px solid #eaeaea;

  width: 100%;
  height: 100%;
  max-width: 448px;
  width: 100%;
  // height: 184px;

  gap: 16px;

  padding: 0 16px;

  .thumbnail {
    max-width: 128px;
    max-height: 128px;
    // width: 100%;
    // height: 100%;
    object-fit: cover; /* 비율을 유지하면서 영역을 채움 */

    &.loading {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding: 16px 0;

    gap: 12px;

    .title_wrapper {
      // height: 86px;

      padding-top: 0;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      gap: 4px;

      .title {
        width: 100%;
        // height: 56px;
        vertical-align: text-top;

        font-size: 24px;
        line-height: 28px; /* 116.667% */
        font-style: normal;
        font-weight: 600;

        letter-spacing: -0.48px;

        color: $gray-dark;

        word-break: break-word;

        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .category_wrapper {
      display: flex;
      // height: 60px;
      align-items: flex-end;
      align-content: flex-end;

      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .detail_wrapper {
      display: flex;
      align-items: center;

      gap: 10px;

      .category {
        @include flexCenter;

        gap: 4px;

        color: $gray-77;
        font-weight: 500;

        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @media all and #{$medium} {
    .thumbnail {
      max-width: 108px;
      max-height: 108px;
    }

    .content {
      gap: 0px;
      .title_wrapper {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}
