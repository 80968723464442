@import 'styles/import';

.floor {
  width: 100%;
  height: 100%;
  font-size: vvw(32);
  font-weight: 700;

  @include flexCenter;

  &.overFive {
    height: vvw(163) !important;
  }

  &.active {
    color: $white;
  }
}

@media all and #{$horizontal} {
  .floor {
    font-size: hvw(32);

    &.overFive {
      height: hvw(120) !important;
    }
  }
}
