@import 'styles/import';

.wrapper {
  display: flex;
  align-items: center;

  text-align: center;

  padding-left: vvw(8);

  gap: vvw(8);

  .divider {
    width: vvw(2);
    height: vvw(12);

    background: $gray-cc;
  }

  .booth_number {
    color: $gray-77;
    font-size: vvw(18);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  // 가로형
  @media all and #{$horizontal} {
    padding-left: hvw(8);

    gap: hvw(8);

    .divider {
      width: hvw(2);
      height: hvw(12);
    }

    .booth_number {
      color: $gray-77;
      font-size: hvw(18);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
