@import 'styles/import';

.wrapper {
  width: vvw(620);
  // max-height: vvw(284);

  background-color: $white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: calc((vvw(82)) + vvw(66));
    // max-height: calc((vvw(162)) + vvw(66));

    background-color: $white;

    .title {
      min-height: vvw(82);

      padding: vvw(40) vvw(60) 0 vvw(60);

      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      word-break: break-all;

      text-align: center;
      font-size: vvw(30);
      font-style: normal;
      font-weight: 700;
      line-height: vvw(42);
      letter-spacing: -0.63px;
    }

    .details_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: vvw(66);

      padding: vvw(12) vvw(20) vvw(24) vvw(20);
    }
  }

  .footer {
    width: 100%;
    height: vvw(136);

    padding: vvw(20);

    @include flexCenter;

    background-color: $white;

    .buttons_wrapper {
      display: flex;
      width: 100%;
    }
  }
}

@media all and #{$horizontal} {
  .wrapper {
    width: hvw(620);
    // max-height: hvw(284);

    .header {
      min-height: calc((hvw(82)) + hvw(66));
      // max-height: calc((hvw(162)) + hvw(66));

      gap: hvw(20);

      .title {
        padding: hvw(40) hvw(60) 0 hvw(60);

        min-height: hvw(82);
        max-height: hvw(162);

        font-size: hvw(30);
        line-height: hvw(42);
      }

      .details_wrapper {
        height: hvw(66);

        padding: hvw(12) hvw(20) hvw(24) hvw(20);
      }
    }

    .footer {
      height: hvw(136);
      padding: hvw(20);
    }
  }
}
