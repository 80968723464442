@import 'styles/import';

.wrapper {
  display: flex;
  width: vvw(696);
  height: vvw(132);
  padding: vvw(24);
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  border-radius: vvw(4);
  border: vvw(1) solid var(--Input-INPUT_ACTIVE, #444);
  background: #fff;

  /* drop_shadow */
  box-shadow: 0px 3px 15px 0px rgba(153, 153, 153, 0.4);

  position: absolute;
  top: calc(vvw(-132) + vvw(-20));

  z-index: 100;

  display: none;

  // @include slide(100%, 100%);
  // @include fadeOutAni();

  &.open {
    bottom: 20px;

    display: block;
    animation: slideUpAni 0.4s forwards;
  }

  &.close {
    display: block;
    animation: slideDownAni 0.4s forwards, fadeOut 0.5s 0.2s forwards;
  }

  .words_wrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: vvw(12);
    align-self: stretch;
    flex-wrap: wrap;

    overflow-x: hidden;
    overflow-y: auto;

    height: vvw(84);
    min-height: vvw(84);

    &::-webkit-scrollbar {
      width: vvw(4);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-cc;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
}

@media all and #{$horizontal} {
  .wrapper {
    width: hvw(760);
    height: hvw(132);
    padding: hvw(24);

    gap: hvw(12);

    top: calc(hvw(-132) + hvw(-20));
    right: 0;

    border-radius: hvw(4);
    border: hvw(1) solid var(--Input-INPUT_ACTIVE, #444);

    .words_wrapper {
      gap: hvw(12);

      height: hvw(84);
      min-height: hvw(84);

      &::-webkit-scrollbar {
        width: hvw(4);
      }
    }
  }
}
