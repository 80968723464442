@import 'styles/import';

.board_image {
  @include flexColumnCenter;

  width: vvw(860);
  height: vvw(1100);

  border-radius: vvw(40);
  background: $gray-f9;

  box-shadow: 0 vvw(4) vvw(40) 0 rgba(0, 0, 0, 0.2);

  > img {
    width: vvw(860);
    height: vvw(1100);

    object-fit: cover;

    border-radius: vvw(40);
  }

  @media all and #{$horizontal} {
    @include flexColumnCenter;

    width: hvw(578);
    height: hvw(740);

    border-radius: hvw(40);
    box-shadow: hvw(4) hvw(4) hvw(32) 0 rgba(0, 0, 0, 0.15);

    > img {
      width: hvw(578);
      height: hvw(740);

      object-fit: cover;

      border-radius: hvw(40);
    }
  }
}

.web_board_image {
  @include flexColumnCenter;

  width: 578px;
  height: 600px;

  background: $gray-f9;

  border-radius: 40px;
  box-shadow: 4px 4px 32px 0 rgba(0, 0, 0, 0.15);

  > img {
    width: 578px;
    height: 600px;
    object-fit: fill;

    border-radius: 40px;
  }

  @media all and #{$medium} {
    width: 390px;
    height: 500px;
    border-radius: 40px;
    > img {
      width: 390px;
      height: 500px;
      object-fit: fill;
      border-radius: 40px;
    }
  }
}
