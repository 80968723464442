@import 'styles/import';

.container {
  cursor: pointer;

  display: flex;

  width: vvw(140);
  height: vvw(36);

  &.active {
    color: $white;
  }

  .button_wrapper {
    @include flexCenter;

    gap: vvw(10);

    border-radius: vvw(19);

    background-color: $white;

    width: 100%;
    height: 100%;

    z-index: 130;

    border: vvw(1) solid #222;

    .icon {
      width: vvw(24);
      height: vvw(24);

      img {
        height: 100%;
      }
    }

    .current_lang {
      color: $gray-99;

      font-size: vvw(18);
      line-height: vvw(21);
    }

    .arrow {
      width: vvw(18);
      height: vvw(18);

      img {
        height: 100%;
      }
    }

    .arrow_hori {
      display: none;
    }
  }

  position: relative;

  .select_box {
    @include flexColumnCenter;

    position: absolute;
    left: 0;
    bottom: vvw(40);

    background-color: $white;
    border-radius: vvw(16);

    border: vvw(1) solid #222;

    width: vvw(140);

    padding: vvw(12);
    gap: vvw(8);

    z-index: 100;

    animation: showSelectBoxAni 1s forwards;

    @keyframes showSelectBoxAni {
      0% {
        bottom: 0;
      }

      100% {
        bottom: vvw(40);
      }
    }

    .lang {
      @include flexCenter;
      width: 100%;
      height: vvw(36);

      background: transparent;
      border-radius: vvw(8);

      font-size: vvw(18);
      line-height: vvw(21);

      color: $gray-77;

      &.active {
        color: $white;
      }
    }
  }

  @media all and #{$horizontal} {
    gap: hvw(16);

    width: 100%;
    height: hvw(120);

    .button_wrapper {
      @include flexColumnCenter;

      gap: 0;

      width: hvw(136);
      height: 100%;

      border-radius: 0;
      background-color: $gray-f9;

      border: none;

      border-left: hvw(1) solid $gray-22;
      border-right: hvw(1) solid $gray-22;

      &.active {
        background-color: $gray-99;
      }

      .icon {
        width: hvw(52);
        height: hvw(52);

        position: relative;

        .arrow_hori {
          display: block;
          position: absolute;
          top: hvw(0);
          right: hvw(-18);

          width: hvw(18);
          height: hvw(18);

          img {
            width: 100%;
          }
        }
      }

      .current_lang {
        font-size: hvw(20);
        line-height: hvw(28);
        color: $gray-44;
      }

      .arrow {
        display: none;
      }
    }

    .select_box {
      left: hvw(-72);
      bottom: hvw(118);

      width: hvw(210);

      z-index: -1;

      gap: 0;
      padding: 0;

      border-radius: 0;
      background-color: $gray-18;

      border-top: hvw(1) solid $gray-77;
      border-left: hvw(1) solid $gray-77;
      border-right: hvw(1) solid $gray-77;
      border-bottom: none;

      animation: showSelectBoxAni 0.7s forwards;

      @keyframes showSelectBoxAni {
        0% {
          bottom: 0;
        }

        100% {
          bottom: hvw(118);
        }
      }

      .lang {
        flex: 1;
        min-height: hvw(68);
        font-size: hvw(20);
        line-height: hvw(28);
        background-color: $gray-18;

        padding: 0 hvw(20);
      }
    }
  }
}
