@import 'styles/import';

.left,
.right {
  width: vvw(52);
  height: vvw(52);

  > img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  @media all and #{$horizontal} {
    width: hvw(52);
    height: hvw(52);
  }
}
