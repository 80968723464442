@import 'styles/import';

.floor_controller {
  display: grid;
  grid-template-columns: repeat(9, 1fr);

  margin: auto;

  &.lessFloor {
    @include flexCenter;
    flex-wrap: wrap;
  }

  .floor {
    width: vvw(80);
    height: vvw(52);

    font-weight: 600;
    font-size: vvw(22);
    line-height: vvw(28);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;

    text-align: center;
    letter-spacing: -0.02em;

    position: relative;

    .border_after {
      width: vvw(40);
      height: vvw(4);

      animation: path 1s forwards;

      @keyframes path {
        0% {
          width: 0;
        }

        100% {
          width: vvw(40);
        }
      }
    }
  }

  @media all and #{$horizontal} {
    gap: hvw(10);

    .floor {
      width: hvw(80);
      height: hvw(52);

      font-size: hvw(22);
      line-height: hvw(28);

      .border_after {
        width: hvw(40);
        height: hvw(4);

        animation: path 1s forwards;

        @keyframes path {
          0% {
            width: 0;
          }

          100% {
            width: hvw(40);
          }
        }
      }
    }
  }
}
