@import 'styles/import';

// multi
.container {
  overflow-y: auto;

  // width: 100%;

  position: absolute;
  left: calc(vvw(-280) + vvw(-24));

  &.default {
    max-height: vvw(1084);
  }

  &.simple {
    max-height: vvw(754);
  }

  .wrapper {
    position: relative;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: vvw(24);
  }

  &::-webkit-scrollbar {
    // background-color: transparent;
    // width: vvw(10);

    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-99;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media all and #{$horizontal} {
    background-color: transparent;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: absolute;
    left: calc(hvw(-300) + hvw(-24));

    &.default {
      max-height: hvw(847);
    }

    &.simple {
      max-height: hvw(460);
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: hvw(12);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
