@import 'styles/import';

.clock {
  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;

  gap: vvw(16);

  .date {
    font-size: vvw(20);
    text-align: right;

    display: flex;
    align-items: center;
    gap: vvw(3);
    font-weight: 325;

    .dot {
      letter-spacing: vvw(3);
    }

    .day {
      font-size: vvw(17);
    }
  }

  .time {
    font-size: vvw(18);
    font-weight: 700;

    .type {
      font-size: vvw(18);
    }
  }

  .pre {
    font-size: vvw(24);
  }
}

@media all and #{$horizontal} {
  .clock {
    gap: hvw(16);
    padding: hvw(20);

    .date {
      font-size: hvw(20);

      gap: hvw(3);

      .dot {
        letter-spacing: hvw(3);
      }

      .day {
        font-size: hvw(17);
      }
    }

    .time {
      font-size: hvw(30);
      font-weight: 500;

      .type {
        font-size: hvw(30);
      }
    }

    .pre {
      font-size: hvw(20);
    }
  }
}
