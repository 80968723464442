@import './import';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: 'Pretendard', Pretendard, 'NotoSansSC', -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;

  -webkit-tap-highlight-color: transparent;

  &::-webkit-scrollbar {
    background-color: $gray-f9;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-cc;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-f9;
  }
}

body {
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  touch-action: none;
  user-select: none;
  overflow: hidden;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}
