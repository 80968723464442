@import 'styles/import';

.tenant_popup {
  width: 100%;
  height: 100%;

  // 다중 contents 레이아웃일 경우 필요
  display: flex;
  align-items: flex-start;
  // justify-content: center;

  &.multi {
    position: relative;

    gap: vvw(24);

    right: calc((vvw(-280) + vvw(-24)) / 2);
  }

  .popup_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    height: 100%;

    // width: vvw(620);

    // min-height: vvw(284);
    // max-height: vvw(1084);

    // background: #fff;
  }
}

// 가로형
@media all and #{$horizontal} {
  .tenant_popup {
    margin-top: 0;

    &.default {
      height: hvw(604);
    }

    &.simple {
      height: auto;
    }

    &.multi {
      gap: hvw(12);

      right: calc((hvw(-300) + hvw(-24)) / 2);
    }
  }
}
