@import 'styles/import';

.container {
  width: 100%;
  height: 100%;

  @include flexCenter;

  .desc {
    text-align: center;
    font-size: vvw(22);
    white-space: nowrap;

    color: $gray-99;
  }

  @media all and #{$horizontal} {
    .desc {
      font-size: hvw(22);
    }
  }
}
