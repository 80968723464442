@import 'styles/import';

.layout {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  justify-content: space-between;
}

.main {
  height: 100%;
}

.web_layout {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  justify-content: space-between;

  /* 화면 크기가 1023px 이하일 때 */
  @media (max-width: 1023px) {
    width: 1025px; /* 최소 화면 너비 고정 */
    overflow-x: auto; /* 가로 스크롤 활성화 */
  }

  /* 화면 크기가 1024px 이상일 때 */
  @media (min-width: 1024px) {
  }
}
