@import 'styles/import';

.container {
  width: 100%;
  height: vvw(140);

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 100;

  @media all and #{$horizontal} {
    height: hvw(120);
    font-size: hvw(30);

    .lang_hori {
      display: block;
      background-color: $gray-22;
      @include flexCenter;
      gap: hvw(16);
      height: 100%;
      width: hvw(136);
      color: $gray-77;
    }
  }

  // @media all and #{$square} {
  //   height: vvw(100);
  //   font-size: vvw(26);
  // }
}

.web_container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 100;

  height: 120px;
  font-size: 30px;

  .lang_hori {
    display: block;
    background-color: $gray-22;
    @include flexCenter;
    gap: 16px;
    height: 100%;
    width: 136px;
    color: $gray-77;
  }

  /* 화면 크기가 1023px 이하일 때 */
  @media (max-width: 1023px) {
  }

  /* 화면 크기가 1024px 이상일 때 */
  @media (min-width: 1024px) {
  }
}
