@import 'styles/import';

.detail_qr {
  display: flex;
  align-items: flex-start;
  position: relative;

  .field_title {
    width: vvw(140);
    height: vvw(48);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: vvw(18);
    font-weight: 600;
    line-height: vvw(24);
    letter-spacing: -0.108px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .field_content_qr {
    width: vvw(380);
    height: vvw(106);
    padding: vvw(12) 0 vvw(12) vvw(20);

    .content {
      border: vvw(1) solid #444;
      display: flex;
      padding: vvw(12);
      width: vvw(82);
      height: vvw(82);
    }
  }

  // 가로형
  @media all and #{$horizontal} {
    .field_title {
      width: hvw(120);
      height: hvw(52);
      font-size: hvw(18);
      line-height: hvw(24);
    }

    .field_content_qr {
      width: hvw(354);
      height: hvw(106);
      padding: hvw(12) 0 hvw(12) hvw(20);
      .content {
        border: hvw(1) solid #444;
        display: flex;
        padding: hvw(12);
        width: hvw(82);
        height: hvw(82);
      }
    }
  }

  // 정방형
  // @media all and #{$square} {
  // }
}
