@import 'styles/import';

.title_wrapper {
  @include flexColumnCenter;

  width: vvw(860);
  height: vvw(231);

  padding: vvw(40) 0;

  .title_and_badge {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: vvw(10) 0;

    gap: vvw(20);

    overflow: hidden;

    .title {
      max-height: vvw(90);

      text-align: center;
      font-size: vvw(36);
      font-style: normal;
      font-weight: 600;
      line-height: vvw(50);
      letter-spacing: -0.72px;

      word-break: break-all;
    }
  }

  @media all and #{$horizontal} {
    background-color: $white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    width: hvw(390);
    height: hvw(740);
    padding: hvw(48) hvw(60);

    border-radius: hvw(40);

    box-shadow: hvw(4) hvw(4) hvw(32) 0 rgba(0, 0, 0, 0.15);

    .title_and_badge {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      gap: hvw(24);

      width: 100%;
      height: 100%;

      padding: 0;

      .title {
        text-align: left;

        height: 100%;
        max-height: 100%;

        font-size: hvw(36);
        font-style: normal;
        font-weight: 600;
        line-height: hvw(46);
        letter-spacing: -0.72px;

        display: flex;
        overflow: hidden;
      }
    }
  }
}

.web_title_wrapper {
  @include flexColumnCenter;

  background-color: $white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 390px;
  height: 600px;
  padding: 48px 60px;

  border-radius: 40px;

  box-shadow: 4px 4px 32px 0 rgba(0, 0, 0, 0.15);

  .title_and_badge {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    gap: 24px;

    width: 100%;
    height: 100%;

    overflow: hidden;

    .title {
      height: 100%;
      max-height: 100%;

      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: -0.72px;

      word-break: break-all;
      display: flex;
      overflow: hidden;
    }
  }

  @media all and #{$medium} {
    width: 390px;
    height: 500px;
    border-radius: 40px;

    box-shadow: 4px 4px 32px 0 rgba(0, 0, 0, 0.15);
  }
}
