@import 'styles/import';

.qr_svg_wrapper {
  width: 100%;
  height: 100%;

  .qr_svg {
    width: 100%;
    height: 100%;
  }
}
