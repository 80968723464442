@import 'styles/import';

.footer {
  width: 100%;
  height: vvw(220);
  bottom: 0;
  @media all and #{$horizontal} {
    height: hvw(120);
    border-top: hvw(1) solid $gray-22;
  }
}

.web_footer {
  width: 100%;
  height: 120px;
  border-top: 1px solid $gray-22;
}
