@import 'styles/import';

.main {
  width: 100%;
  height: 100%;

  border-right: vvw(2) solid #222;

  .logo {
    width: 100%;
    height: 100%;

    padding: vvw(24);

    @include flexCenter;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media all and #{$horizontal} {
    width: 100%;
    height: hvw(120);

    border-right: hvw(1) solid #222;

    .logo {
      width: 100%;
      height: 100%;

      padding: hvw(24);

      @include flexCenter;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.web_main {
  width: 100%;
  height: 120px;

  border-right: 1px solid #222;

  .logo {
    width: 100%;
    height: 100%;

    padding: 24px;

    @include flexCenter;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
