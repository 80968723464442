@import 'styles/import';

.detail_image {
  @include flexCenter;
  width: 100%;
  height: vvw(480);

  background-color: $white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // 정방형
  // @media all and #{$square} {
  //   height: vvw(300);
  // }

  // 가로형
  @media all and #{$horizontal} {
    width: hvw(785);
    height: hvw(604);
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
