@import 'styles/import';

.board_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media all and #{$horizontal} {
    display: flex;
    flex-direction: row-reverse;
  }
}

.web_board_wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}
