@import 'styles/import';

.container {
  @include flexColumnCenter;

  width: 100%;
  min-height: vvw(1400);
  height: 100%;

  padding-top: calc(vvw(14) + vvw(28));

  .tenant_wrapper {
    width: calc(vvw(502) + vvw(502) + vvw(16));
    height: 100%;

    .slides {
      height: 100%;

      flex-shrink: 0;

      display: grid;

      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
      gap: vvw(16);
    }
  }

  .pagination_wrapper {
    @include flexCenter;

    position: relative;

    width: 100%;
    height: vvw(114);
    padding: vvw(31) 0;

    &.visible {
      display: block;
    }

    &.invisible {
      display: none;
    }
  }
}

// 가로형
@media all and #{$horizontal} {
  .container {
    width: 100vw;
    min-height: hvw(718);
    height: 100%;

    padding: hvw(40) hvw(46) hvw(28) hvw(46);

    gap: hvw(24);

    .tenant_wrapper {
      width: calc((hvw(448) * 4) + (hvw(12) * 3));
      height: 100%;

      .slides {
        height: 100%;

        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: hvw(12);
      }
    }

    .pagination_wrapper {
      @include flexCenter;

      width: 100%;
      height: hvw(52);
      padding: 0;
    }
  }
}

// 정방형
// @media all and #{$square} {
//   .container {
//     .tenant_wrapper {
//       padding: vvw(38) vvw(26) 0 vvw(26);

//       .slides {
//         height: calc(100% - vvw(75));

//         grid-template-columns: repeat(2, 1fr);
//         grid-template-rows: repeat(3, 1fr);
//         gap: vvw(14);
//       }
//     }
//   }
// }

.web_container {
  @include flexColumnCenter;

  width: 100vw;
  height: calc(100% - 240px);

  // min-height: hvw(718);

  padding: 40px 46px 28px 46px;

  gap: 24px;

  .tenant_wrapper {
    display: flex;
    width: 100%; //calc((448px * 4) + (12px * 3));
    height: 100%;

    .slides {
      height: 100%;

      flex-shrink: 0;

      display: grid;

      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 12px;
    }
  }

  .pagination_wrapper {
    @include flexCenter;

    position: relative;

    width: 100%;
    height: 52px;
    padding: 0;

    &.visible {
      display: block;
    }

    &.invisible {
      display: none;
    }
  }
}
