@import 'styles/import';

@media all and #{$horizontal} {
  .mall_info_wrapper {
    @include flexCenter;

    width: hvw(358);
    min-width: hvw(358);
    height: hvw(120);

    .mall_info {
      @include flexCenter;

      width: 100%;
      height: 100%;
    }
  }
}
.web_mall_info_wrapper {
  @include flexCenter;
  // width: 358px;
  // min-width: 358px;
  height: 120px;

  .mall_info {
    @include flexCenter;

    width: 100%;
    height: 100%;
  }
}
