@import 'styles/import';

.floor_dot {
  width: vvw(3);
  height: vvw(3);
  background-color: $gray-99;

  margin: 0 vvw(6);

  // 가로형
  @media all and #{$horizontal} {
    width: hvw(3);
    height: hvw(3);

    margin: 0 hvw(6);
  }
}
