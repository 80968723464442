@import 'styles/import';

.keypad {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  width: vvw(696);
  height: vvw(104);

  .chosung_wrapper {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    width: vvw(500);
    height: vvw(104);
  }

  .alphabet_wrapper {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    width: vvw(624);
    height: vvw(104);
  }

  .keypad_item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: vvw(48);
    height: vvw(48);

    font-weight: 600;
    font-size: vvw(22);
  }

  @media all and #{$horizontal} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    width: 100%;
    height: hvw(48);

    text-align: center;

    .chosung_wrapper {
      width: 100%;
      height: hvw(48);
    }

    .alphabet_wrapper {
      width: 100%;
      height: hvw(48);
    }

    .keypad_item {
      display: flex;
      justify-content: center;
      align-items: center;

      width: hvw(48);
      height: hvw(48);

      font-size: hvw(22);
    }
  }
}
