@import 'styles/import';

.container {
  position: relative;

  .custom_board_list {
    height: vvw(1488);
    padding: 0 vvw(130) vvw(60) vvw(130);
    overflow-y: auto;
    margin-right: vvw(10);

    &::-webkit-scrollbar {
      width: vvw(6);
    }

    .scroll {
      height: vvw(60);
    }

    .list {
      margin-right: vvw(20);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: vvw(60);
    }
  }

  .top_button {
    z-index: 100;
    right: vvw(40);
    bottom: vvw(20);
    position: absolute;
    width: vvw(60);
    height: vvw(60);
    background-color: $main-black;

    @include flexCenter;

    .icon {
      width: vvw(28);
      height: vvw(28);

      img {
        height: 100%;
      }
    }
  }

  @media all and #{$horizontal} {
    height: calc(100% - hvw(184));

    .custom_board_list {
      display: flex;
      overflow-y: hidden;
      width: hvw(1920);
      padding: 0;

      height: 100%;

      .scroll {
        height: vvw(0);
      }

      .list {
        overflow-x: scroll;
        display: flex;
        gap: hvw(60);
        margin-right: 0;
        padding-bottom: hvw(20);
        height: 100%;

        &::-webkit-scrollbar {
          height: hvw(6);
        }
      }
    }

    .top_button {
      z-index: 100;
      right: hvw(20);
      bottom: hvw(20);
      position: absolute;
      width: hvw(60);
      height: hvw(60);
      background-color: $main-black;

      @include flexCenter;

      .icon {
        width: hvw(28);
        height: hvw(28);

        img {
          height: 100%;
        }
      }
    }
  }
}
