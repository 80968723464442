@import 'styles/import';

.menu_wrapper {
  width: 100%;
  height: 100%;

  @include flexColumnCenter;

  text-decoration: none;

  position: relative;

  &.active {
    color: $white;
  }

  &.border_right {
    border-right: vvw(2) solid #222;
  }

  .menu {
    @include flexColumnCenter;

    position: relative;

    gap: vvw(6);
    white-space: nowrap;

    .icon {
      width: vvw(40);
      height: vvw(40);
      > img {
        height: 100%;
      }
    }

    .menu_text {
      @include flexColumnCenter;

      font-size: vvw(24);
      font-weight: 500;
      line-height: vvw(32);
      letter-spacing: -0.24px;
    }
  }

  .title {
    padding-top: vvw(5);

    &.none {
      display: none;
    }
  }

  @media all and #{$horizontal} {
    @include flexCenter;
    gap: hvw(8);

    height: hvw(120);

    &.border_right {
      border-right: hvw(1) solid #222;
    }

    .menu {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      gap: hvw(8);

      .icon {
        width: hvw(40);
        height: hvw(40);
      }

      .menu_text {
        font-size: hvw(30);
        line-height: hvw(42);

        padding-bottom: hvw(2);
      }
    }
  }
}

// WEB 버전
.web_menu_wrapper {
  width: 100%;
  height: 120px;

  @include flexCenter;

  text-decoration: none;

  position: relative;

  &.active {
    color: $white;
  }

  &.border_right {
    border-right: 1px solid #222;
  }

  .menu {
    @include flexCenter;

    position: relative;

    gap: 8px;
    white-space: nowrap;

    .icon {
      width: 40px;
      height: 40px;
      > img {
        height: 100%;
      }
    }

    .menu_text {
      @include flexColumnCenter;

      font-size: 30px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: -0.24px;
      padding-bottom: 2px;
    }
  }

  .title {
    padding-top: vvw(5);

    &.none {
      display: none;
    }
  }
}
