@import 'styles/import';

.point_name {
  font-size: vvw(18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  // 가로형
  @media all and #{$horizontal} {
    font-size: hvw(18);
  }
}
