@import 'styles/import';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: vvw(696);
  height: vvw(396);

  padding: vvw(60) vvw(80);

  gap: vvw(60);

  background-color: #fff;

  font-weight: 500;
  font-size: vvw(32);
  line-height: vvw(36);

  text-align: center;

  color: $main-black;

  .transport_wrapper {
    display: flex;

    gap: vvw(28);

    .transport {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: vvw(160);
      height: vvw(160);

      padding: 0px vvw(32);
      gap: vvw(4);

      .icon {
        width: vvw(80);
        height: vvw(80);

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > span {
        white-space: nowrap;

        font-weight: 500;
        font-size: vvw(22);
        line-height: vvw(26);
        display: flex;
        align-items: center;

        color: #ffffff;
      }
    }
  }

  @media all and #{$horizontal} {
    width: hvw(696);
    height: hvw(396);

    padding: hvw(60) hvw(80);

    gap: hvw(60);

    font-size: hvw(32);
    line-height: hvw(36);

    .transport_wrapper {
      gap: hvw(28);

      .transport {
        width: hvw(160);
        height: hvw(160);

        padding: 0px hvw(32);
        gap: hvw(4);

        .icon {
          width: hvw(80);
          height: hvw(80);
        }

        > span {
          font-size: hvw(22);
          line-height: hvw(26);
        }
      }
    }
  }
}
