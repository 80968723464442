@import 'styles/import';

.container {
  width: vvw(136);
  height: vvw(140);
  min-height: vvw(140);

  font-size: vvw(20);

  color: $gray-f9;

  @include flexColumnCenter;
  gap: vvw(6);

  .icon {
    width: vvw(48);
    height: vvw(48);

    img {
      height: 100%;
    }
  }

  @media all and #{$horizontal} {
    width: hvw(136);
    height: hvw(140);
    min-height: hvw(140);

    font-size: hvw(20);
    gap: hvw(6);

    .icon {
      width: hvw(48);
      height: hvw(48);
    }
  }
}
