@import 'styles/import';

.item {
  display: flex;
  align-items: center;

  .triangle {
    height: vvw(14);

    img {
      height: 100%;
    }
  }

  .container {
    .cover {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      opacity: 0.5;
      background: #000;
    }

    display: flex;

    position: relative;

    width: vvw(280);
    padding: vvw(10);

    background-color: $white;

    box-shadow: vvw(8) vvw(5) vvw(25) 0 rgba(0, 0, 0, 0.3);

    &.selected {
      background-color: $white;
    }

    .logo {
      width: vvw(100);
      height: vvw(100);
      flex-shrink: 0;
      border: vvw(1) solid $gray-ea;
      background-color: $white;

      img {
        height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }

    .name {
      width: 100%;

      display: flex;
      align-items: center;

      word-break: break-all;
      padding-left: vvw(20);
      padding-right: vvw(20);

      .text {
        font-size: vvw(18);
        color: $gray-44;
        line-height: vvw(20);
        padding-top: vvw(4);
        padding-bottom: vvw(4);

        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media all and #{$horizontal} {
  .item {
    .triangle {
      height: hvw(14);
    }

    .container {
      width: hvw(300);
      padding: hvw(10);

      .logo {
        width: hvw(100);
        height: hvw(100);
        border: hvw(1) solid $gray-ea;
      }

      .name {
        padding-left: hvw(20);
        padding-right: hvw(20);

        .text {
          padding: hvw(4);
          line-height: hvw(22);
          font-size: hvw(18);
        }
      }
    }

    &:last-of-type {
      margin-bottom: hvw(236);
    }
  }
}
