@import 'styles/import';

.floor_name {
  font-size: vvw(18);
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media all and #{$horizontal} {
    font-size: hvw(18);
    font-weight: 700;
  }
}
