@import 'styles/import';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  gap: vvw(8);

  flex: 1;

  width: 100%;
  height: vvw(96);

  &.location_bg {
    background-color: $gray-22;
  }

  &.navi_bg {
    background-color: $point-blue;
  }

  font-weight: 600;
  font-size: vvw(24);
  text-transform: capitalize;
  color: $white;

  border: none;

  img {
    width: vvw(28);
    height: vvw(28);
  }

  // 가로형
  @media all and #{$horizontal} {
    gap: hvw(8);
    height: hvw(96);

    font-size: hvw(24);

    img {
      width: hvw(28);
      height: hvw(28);
    }
  }

  // 정방형
  // @media all and #{$square} {
  //   height: vvw(80);

  //   font-size: vvw(20);

  //   img {
  //     width: vvw(24);
  //     height: vvw(24);
  //   }
  // }
}
