@import 'styles/import';

.container {
  background-color: $white;
  height: inherit;

  width: vvw(136);

  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.no_floor {
    height: min-content;

    top: auto;
    bottom: 0;
    right: 0;
  }

  .floors {
    height: calc(100% - vvw(139.84));
    overflow: hidden;

    border-left: 1px solid $gray-22;
    border-right: 1px solid $gray-22;

    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media all and #{$horizontal} {
    position: fixed;
    bottom: 0;
    right: 0;

    width: hvw(136);
    height: calc(100% - hvw(120));

    &.no_floor {
      position: fixed;

      top: auto;
      bottom: hvw(120);
      right: 0;
    }

    .floors {
      height: calc(100% - hvw(139.84));
      overflow: hidden;
    }
  }
}
